<template>
  <div class="container tw-max-w-[900px] talent-choose-interveiw-slot">

        
    <div
      v-if="status"
      class="tw-flex tw-items-start tw-justify-between tw-py-4"
    >
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-2">
        <h1 v-if="clientData" class="tw-text-3xl tw-font-normal tw-leading-10">
          {{ $t('requestInterview.interviewProccessFor') }} {{ clientData?.name }}
        </h1>
        <div v-if="status.id === 'talent_unavailable'"
              class="tw-bg-[#ff4a551a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#ff4a55] tw-text-sm tw-font-medium tw-leading-normal tw-w-max">
              {{$t('requestInterview.talentUnavailable')}}
            </div>
             <div v-if="status.id === 'canceled'"
              class="tw-bg-[#ff4a551a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#ff4a55] tw-text-sm tw-font-medium tw-leading-normal tw-w-max">
               {{$t('requestInterview.canceled')}}
            </div>
            <div v-if=" status.id === 'client_pending'"
              class="tw-bg-[#f876141a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#f87614] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
                {{$t('requestInterview.pendingClientConfirmationOnNewSlots')}}
            </div>
            <div v-if="status.id === 'client_pending_update'"
              class="tw-bg-[#f876141a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#f87614] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
                {{$t('requestInterview.pendingClientTimeSlots')}}
            </div>
            <div v-if="status.id === 'scheduled'"
              class="tw-bg-[#3bd4ae1a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#3BD4AE] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
               {{$t('requestInterview.interviewScheduled')}}
            </div>
             <div v-if="status.id === 'completed'"
              class="tw-bg-[#3bd4ae1a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#3BD4AE] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
               {{$t('requestInterview.interviewCompleted')}}
            </div>
            <div v-if="status.id === 'talent_pending'"
              class="tw-bg-[#1f39d21a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#1F39D2] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
              {{$t('requestInterview.pendingTalentMeetingConfirmation')}}
            </div>
             <div v-if="status.id === 'talent_pending_update'"
              class="tw-bg-[#1f39d21a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#1F39D2] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
               {{$t('requestInterview.pendingTalentConfirmation')}}
            </div>
      <!--   <span
          class="tw-bg-[#fcece4] tw-text-[#fca05c] tw-text-sm tw-font-normal tw-leading-7 tw-px-4 tw-py-2 tw-rounded-full"
        >
          {{$t('requestInterview.clientPending') }}
        </span> -->


      </div>
    </div>


    <div
       v-if="requiredResource"
      class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-bg-white tw-rounded-3xl md:tw-px-14 md:tw-py-4 tw-mb-4"
      style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)"
    >
      <div class="tw-flex tw-items-center">
        <img
          class="tw-flex tw-justify-center tw-w-12 tw-h-12 tw-me-2"
          src="@/assets/images/icons/Group 10 Copy.svg"
          alt=""
        />
       
      </div>
      
      
      <div
        class="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-divide-y md:tw-divide-y-0 md:tw-divide-x"
        :class="$i18n.locale !== 'en' ? 'md:tw-divide-x-reverse' : 'md:tw-divide-x'"
      >
        <div
          class="tw-flex tw-items-center tw-justify-between tw-w-full md:tw-w-1/3 md:tw-py-3 tw-pt-3 md:tw-pe-6"
        >
          <div class="text-[#292929] tw-text-sm tw-font-medium tw-leading-7">
            {{ $t('requestInterview.EngagementInfo') }} :
          </div>
          <div
            class="tw-text-right tw-text-[#1b1b1b] tw-text-sm tw-font-semibold tw-leading-[22.96px]"
          >
            {{  availabilitiesList.find(i => i.id == requiredResource.contract_type.id )?.name }}
          </div>
        </div>
        <div
          class="tw-flex tw-items-center tw-justify-between tw-w-full md:tw-w-1/3 md:tw-py-3 tw-py-3 md:tw-px-6"
        >
          <div class="tw-text-[#292929] tw-text-sm tw-font-medium tw-leading-7">
             {{ $t('requestInterview.salary') }} : 
          </div>
          <div
            class="tw-text-right tw-text-[#1b1b1b] tw-text-sm tw-font-semibold tw-leading-[22.96px]"
          >
            {{ requiredResource.contract_type.id == 2 ? fullTimeSalary?.basic_salary : partTimeSalary?.basic_salary }}   <span>$</span>
          </div>
        </div>
        <div
          class="tw-flex tw-items-center tw-justify-between tw-w-full md:tw-w-1/3 md:tw-py-3 tw-pt-3 md:tw-ps-6"
        >
          <div class="tw-text-[#292929] tw-text-sm tw-font-medium tw-leading-7">
            {{ $t('requestInterview.duration') }} :  
          </div>
          <div
            class="tw-text-right text-[#1b1b1b] tw-text-sm tw-font-semibold tw-leading-[22.96px]"
          >
            {{
              durationsList.find(
                (i) => i.id == requiredResource.duration
              )?.name
            }}
          </div>
        </div>
      </div>

    </div>




    <div
      class="md:tw-px-14 md:tw-py-10 tw-px-8 tw-py-6 tw-bg-white tw-shadow tw-flex-col tw-justify-start tw-items-start gap-7 tw-flex tw-z-20 tw-max-w-[900px] tw-my-8 tw-m-auto tw-rounded-2xl"
    >
      <div v-if="loading" class="tw-w-full tw-flex tw-flex-col tw-space-y-4">
        <div
          class="tw-w-full tw-h-6 tw-bg-gray-200 tw-rounded-md tw-animate-pulse"
        ></div>
        <div
          class="tw-w-3/4 tw-h-6 tw-bg-gray-200 tw-rounded-md tw-animate-pulse"
        ></div>
        <div
          class="tw-w-1/2 tw-h-6 tw-bg-gray-200 tw-rounded-md tw-animate-pulse"
        ></div>
      </div>
      <div
        v-else-if="status && status.id == 'talent_pending'"
        class="tw-w-full"
      >
        <div
          class="tw-flex tw-flex-col md:tw-flex-row tw-items-start tw-justify-between tw-gap-4"
        >
          <div class="tw-flex tw-flex-col">
            <h2
              class="tw-text-[#422e87] md:tw-text-2xl tw-text-lg tw-font-semibold md:tw-leading-10"
            >
              {{ $t("requestInterview.selectInterveiwTimeHeader") }}
            </h2>
            <p
              class="tw-text-[#8d8d8d] md:tw-text-sm tw-text-xs tw-font-normal tw-leading-relaxed tw-mb-0"
            >
              {{
                $t("requestInterview.selectInterveiwTimeSubheader", {
                  name: `${clientData?.client?.first_name} ${clientData?.client?.last_name}`,
                })
              }}
            </p>
          </div>
          <div class="md:tw-w-1/3 tw-w-full">
            <v-select
              placeholder="Select a timezone"
              v-model="selectedTimezone"
              :options="timezones"
              :clearable="false"
              class="style-chooser tw-w-full"
              label="name"
              :reduce="(name) => name.value"
            >
            </v-select>
          </div>
        </div>

        <hr
          class="tw-w-full tw-h-[1px] tw-border tw-border-[rgba(0,0,0,0.19)]"
        />

        <div class="tw-flex tw-flex-col tw-space-y-4">
          <div
            class="form-check d-flex"
            v-for="(option, i) in interviewSlots"
            :key="i"
          >
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              :id="option.value"
              v-model="selected"
              :value="option"
              :disabled="option.isPast"
            />
            <label class="form-check-label tw-ms-2" :for="option.value">
              <div v-if="option.value == 'Unavailable'">
                <div v-if="selected?.value === option.value">
                  <span
                    class="tw-text-[#1e2022] md:tw-text-[1.125rem] tw-text-[1rem] tw-font-medium tw-leading-loose tw-mt-1"
                  >
                    {{ $t("requestInterview.unavailable") }}
                  </span>
                </div>
                <div v-else>
                  <span
                    class="tw-text-[#4f4e4e] md:tw-text-[1rem] tw-text-[0.825rem] tw-font-normal tw-leading-7 tw-mt-1"
                  >
                    {{ $t("requestInterview.unavailable") }}
                  </span>
                </div>
              </div>
              <div v-else>
                <div v-if="selected?.value === option.value">
                  <span
                    class="tw-text-[#1e2022] md:tw-text-[1.125rem] tw-text-[1rem] tw-font-medium tw-leading-loose"
                  >
                    {{ option.value }}
                  </span>
                  <span
                    class="tw-text-black md:tw-text-[1rem] tw-text-[0.825rem] tw-font-medium font-['Poppins'] tw-leading-loose"
                  >
                    {{
                      timezones.find((tz) => tz.value == selectedTimezone).name
                    }}
                  </span>
                </div>
                <div v-else>
                  <span
                    class="tw-text-[#4f4e4e] md:tw-text-[1.125rem] tw-text-[1rem] tw-font-normal tw-leading-7"
                  >
                    {{ option.value }}
                  </span>
                  <span
                    class="tw-text-[#979797] md:tw-text-[1rem] tw-text-[0.825rem] tw-font-normal tw-leading-7"
                  >
                    {{
                      timezones.find((tz) => tz.value == selectedTimezone).name
                    }}
                  </span>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div v-if="selected?.value == 'Unavailable'" class="tw-mt-8">
          <div class="ltr">
            <Calendar
              ref="datePickerRef"
              :disabled-dates="disabledDates"
              v-model="selectedDate"
              :min-date="new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)"
              :attributes="attributes"
              :trim-weeks="false"
              is-expanded
              @dayclick="onDayClick"
            />
          </div>
          <div class="tw-mt-5 tw-flex tw-flex-row tw-justify-between tw-gap-4">
            <div
              class="tw-flex tw-flex-col tw-justify-between tw-w-full tw-mb-5"
              v-for="(day, i) in days"
              :key="i"
            >
              <div
                class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full"
              >
                <div class="tw-flex md:tw-flex-row tw-flex-col">
                  <span
                    class="tw-text-[#484d54] tw-text-base tw-leading-relaxed tw-me-2"
                  >
                    {{ $t("requestInterview.selectTimeFor") }}
                  </span>
                  <span
                    class="tw-text-[#484d54] tw-text-base tw-font-semibold tw-leading-relaxed"
                  >
                    {{ dataFormat(day.date) }}</span
                  >
                </div>

                <div
                  class="time-slot tw-flex tw-flex-row tw-border-[#C2C2C2] tw-border-solid tw-rounded-lg tw-py-2 tw-px-3 tw-border"
                >
                  <img src="@/assets/images/icons/time.svg" alt="time" />
                  <DatePicker
                    mode="time"
                    v-model="day.time"
                    :valid-hours="getValidHours[i]"
                    class="ltr"
                    :minute-increment="30"
                    is24hr
                  />
                </div>
              </div>
              <VulidateError
                class="tw-flex"
                :validationField="$v.days.$each[i].time"
                :params="['required']"
              />
            </div>
          </div>
        </div>

        <div v-if="error" class="tw-text-red-500 tw-text-sm tw-mt-2">
          {{ $t("requestInterview.selectTimeError") }}
        </div>

        <div class="baseBtn tw-mt-4 tw-text-end" v-if="status && status.id == 'talent_pending'">
          <button
            @click="submit"
            type="button"
            :disabled="!canSubmit"
            class="btn btnstyleUpdate btnSecondary !tw-px-12"
          >
            {{ $t("requestInterview.submit") }}
          </button>
        </div>
        <!-- Existing content here -->
      </div>
      <div
        v-else-if="talentSuggestedNewSlot || talentSelectedExistingSlot"
        class="tw-w-full"
      >
        <div class="tw-w-full tw-flex tw-gap-4">
          <img
            src="@/assets/images/undraw_schedule_meeting_52nu-2 1.svg"
            alt="time"
          />
          <div
            class="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2"
          >
            <h2 class="tw-text-[#1E2022] tw-text-lg tw-font-semibold">
              <template v-if="talentSuggestedNewSlot">
                {{ $t("requestInterview.selectInterveiwTimeHeaderClient") }}
              </template>
              <template v-else>
                {{
                  $t("requestInterview.selectInterveiwTimeHeaderClientSuccess")
                }}
              </template>
            </h2>
            <p class="tw-text-[#422E87] tw-text-sm tw-font-normal">
              <template v-if="talentSuggestedNewSlot">
                {{
                  $t("requestInterview.selectInterveiwTimeHeaderClientExisting")
                }}
              </template>
              <template v-else>
                {{
                  $t(
                    "requestInterview.selectInterveiwTimeHeaderClientSuccessParagraph"
                  )
                }}
              </template>
            </p>
          </div>
        </div>

        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-center tw-bg-[#f8f8f8] tw-border tw-border-[#ececec] tw-py-2 tw-px-6 tw-rounded-lg tw-mt-8"
        >
          <small class="tw-text-[#494D55] tw-font-normal">
            <template v-if="talentSuggestedNewSlot">
              {{ $t("requestInterview.NewSlot") }}:
            </template>
            <template v-else> {{ $t("requestInterview.timeSlot") }}: </template>
          </small>
          <span class="tw-text-[#1A202C] tw-text-sm tw-font-normal ltr">
            {{ meetingTime(responseInterviewSlots[0].start, responseInterviewSlots[0].timezone )}} 
            
          </span>
        </div>
      </div>
      <div v-else class="tw-w-full">
        <div class="tw-w-full tw-flex tw-gap-4">
          <img
            src="@/assets/images/undraw_schedule_meeting_52nu-2 1.svg"
            alt="time"
          />
          <div
            class="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2"
          >
            <h2 class="tw-text-[#1E2022] tw-text-lg tw-font-semibold">
              {{
                $t("requestInterview.selectInterveiwTimeIsNoLongerAvailable")
              }}
            </h2>
            <p class="tw-text-[#422E87] tw-text-sm tw-font-normal" v-if="false" >
              {{
                $t(
                  "requestInterview.selectInterveiwTimeIsNoLongerAvailableParagraph"
                )
              }}
            </p>
          </div>
        </div>
      </div>

      <ChooseInterviewSlotConfirmModel
        v-if="isChooseInterviewSlotConfirmModelOpen"
        :isOpen="isChooseInterviewSlotConfirmModelOpen"
        :interviewSlots="interviewSlots"
        :selected="selected"
        :selectedTimezone="selectedTimezone"
        :timezones="timezones"
        @update="isChooseInterviewSlotConfirmModelOpen = $event"
        @confirm="confirm($event)"
      />
      <UnavailableConfirmModel
        v-if="isUnavailableConfirmModelOpen"
        :isOpen="isUnavailableConfirmModelOpen"
        @update="isUnavailableConfirmModelOpen = $event"
        @confirm="confirm($event)"
      />

      <selectedSlotSuccessfully
        v-if="isSelectedSlotSuccessfullyOpen"
        :isOpen="isSelectedSlotSuccessfullyOpen"
        :interviewSlots="interviewSlots"
        :selected="selected"
        :selectedTimezone="selectedTimezone"
        :timezones="timezones"
        @update="isSelectedSlotSuccessfullyOpen = $event"
      />

      <SelectedSlotDecline
        v-if="isSelectedSlotDeclineOpen"
        :isOpen="isSelectedSlotDeclineOpen"
        @update="isSelectedSlotDeclineOpen = $event"
      />
    </div>
  </div>
</template>

<script>
import momentTimezone from "moment-timezone";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import VulidateError from "@/components/shared/VulidateError/vulidateError.vue";
import ChooseInterviewSlotConfirmModel from "@/components/ChooseInterviewSlot/ChooseInterviewSlotConfirmModel.vue";
import UnavailableConfirmModel from "@/components/ChooseInterviewSlot/UnavailableConfirmModel.vue";
import SelectedSlotSuccessfully from "@/components/ChooseInterviewSlot/SelectedSlotSuccessfully.vue";
import SelectedSlotDecline from "@/components/ChooseInterviewSlot/SelectedSlotDecline.vue";
import toast from "@/services/toast.js";
import { DatePicker, Calendar } from "v-calendar";

export default {
  components: {
    DatePicker,
    Calendar,
    ChooseInterviewSlotConfirmModel,
    UnavailableConfirmModel,
    SelectedSlotSuccessfully,
    SelectedSlotDecline,
    VulidateError,
  },
  data() {
    return {
      isChooseInterviewSlotConfirmModelOpen: false,
      isUnavailableConfirmModelOpen: false,
      isSelectedSlotSuccessfullyOpen: false,
      isSelectedSlotDeclineOpen: false, //
      selected: null,
      selectedDate: null,
      clientData: null,
      partTimeSalary: null,
      fullTimeSalary: null,
      responseInterviewSlots: [],
      status: null,
      days: [],
      requiredResource: null,
      error: false,
      options: [{ value: "Unavailable" }],
      selectedTimezone: "Africa/Cairo",
      timezones: [],
      loading: false,
      loadingTimeZones: false,
      disabledDates: [
        { weekdays: [6, 7] }, // Disable weekends (Saturday and Sunday)
        {
          start: new Date('2025-03-30'),
          end: new Date('2025-04-07')
        }
      ],
      durationsList: [
        {
          id: 3,
          name: this.$t("clientWizard.threeMonths"),
        },
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ],
    };
  },
  validations: {
    days: {
      $each: {
        time: {
          required,
        },
      },
    },
  },
  mounted() {
    this.loadTimezones();
    this.getInterviewSlots();
  },
  computed: {
      availabilitiesList() {
      return [
        {
          id: 2,
          name: this.$t("clientWizard.fullTime"),
        },
        {
          id: 1,
          name: this.$t("clientWizard.partTime"),
        },
      ]      
    },
    getValidHours() {
      // Update to handle single date
      return this.days.map((date) => {
        const validHoursUTC = [7, 8, 9, 10, 11, 12, 13, 14, 15];
        const validHoursLocal = validHoursUTC.map((hour) => {
          const localHour = momentTimezone
            .tz(new Date(Date.UTC(1970, 0, 1, hour)), this.selectedTimezone)
            .hour();
          return localHour;
        });

        const today = momentTimezone().tz(this.selectedTimezone).toDate();
        const selectedDate = momentTimezone(date.date)
          .tz(this.selectedTimezone)
          .toDate();
        if (today.toDateString() === selectedDate.toDateString()) {
          const currentHour = today.getHours();
          return validHoursLocal.filter((hour) => hour > currentHour);
        }
        return validHoursLocal;
      });
    },
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        key: date.toDateString(),
        dates: date,
      }));
    },
    interviewSlots() {
      return this.options.map((option) => {
        if (this.$i18n.locale == "en") {
          moment.locale("en");
        } else {
          moment.locale("ar");
        }
        const isPast =
          option.value !== "Unavailable" &&
          moment(option.value).tz(this.selectedTimezone).isBefore(moment());

        return {
          id: option.id,
          value:
            option.value !== "Unavailable"
              ? momentTimezone
                  .tz(option.value, option?.slot?.timezone)
                  .tz(this.selectedTimezone)
                  .format(
                    `DD MMMM YYYY [${
                      this.$i18n.locale == "en" ? "at" : "في"
                    }] hh:mm A`
                  )
              : option.value,
          isPast: isPast,
        };
      });
    },
    talentSelectedExistingSlot() {
      return (
        this.interview &&
        this.interview.meeting_start_time &&
        this.responseInterviewSlots.length == 0 &&
        this.status.id == "scheduled"
      );
    },
    talentSuggestedNewSlot() {
      return (
        !this.interview &&
        this.responseInterviewSlots.length == 1 &&
        this.status.id == "client_pending"
      );
    },
    TalentSlot() {
      if (this.talentSelectedExistingSlot)
        return moment(this.interview.meeting_start_time)
          .tz(this.selectedTimezone)
          .format(
            `DD MMMM YYYY [${this.$i18n.locale == "en" ? "at" : "في"}] hh:mm A`
          );
      else
        return moment(this.responseInterviewSlots[0].start)
          .tz(this.selectedTimezone)
          .format(
            `DD MMMM YYYY [${this.$i18n.locale == "en" ? "at" : "في"}] hh:mm A`
          );
    },
    canSubmit() {
      if (this.selected && this.selected.value !== "Unavailable") return true;
      return this.days.length > 0 && this.days.every((day) => day.time);
    },
  },
  watch: {
    selectedTimezone: {
      immediate: true,

      handler() {
        this.days = [];
      },
    },
    "$i18n.locale"() {
      this.durationsList = [
        {
          id: 3,
          name: this.$t("clientWizard.threeMonths"),
        },
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ];

      this.getInterviewSlots();
    },
  },
  methods: {
    meetingTime(time, timezone) {
      if (this.$i18n.locale == "en") {
          moment.locale("en");
          return `${moment(time).format("DD MMMM YYYY")} at ${moment(time).format("h:mm A")} 
          ${timezone == 'UTC'? 'UTC time' :  this.timezones.find((tz) => tz.value == timezone )?.name }`;
      } else {
          moment.locale("ar");
          return `${timezone == 'UTC'? 'UTC time' :  this.timezones.find((tz) => tz.value == timezone )?.name } 
          ${moment(time).format("DD MMMM YYYY")} فى ${moment(time).format("h:mm A")} `;
      }
    },
    loadTimezones() {
      this.selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let timezones = momentTimezone.tz.names();

      let uniceTimezones = new Set(timezones);

      timezones = [...uniceTimezones];

      timezones = timezones.map((timezone) => {
        const gmtOffset = momentTimezone()
          .tz(timezone)
          .format("Z")
          .replace(":00", "");
        return {
          name: `${timezone.split("/")[1]} ${
            timezone.split("/")[2]
              ? "/" + timezone.split("/")[2].replace("_", " ")
              : ""
          }  (GMT${gmtOffset})`,
          value: timezone,
        };
      }).filter((timezone) => !timezone.name.includes('undefined'));
      this.timezones = timezones;
    },
    dataFormat(date) {
      if (this.$i18n.locale == "en") {
        moment.locale("en");
        return moment(date).format("D MMM");
      } else {
        moment.locale("ar");
        return moment(date).format("D MMM");
      }
    },
    onDayClick(day) {
      if (day.isDisabled) return;

      // Replace the existing days array with a single day
      this.days = [
        {
          id: day.id,
          date: day.date,
          time: null,
        },
      ];

      this.$nextTick(() => {
        document.body.click();
      });

      // Remove the error since we can't exceed limit anymore
      this.error = false;
    },
    getInterviewSlots(load = true) {
      // get interview slots
      this.loading = load;
      this.axios
        .get(`/api/talent-selection/${this.$route.params.id}`)
        .then((response) => {
          this.clientData = response.data.data.client_project;
          this.requiredResource = response.data.data.required_resource;
          this.talent = response.data.data.talent;
          this.status = response.data.data.status;
          this.partTimeSalary = response.data.data.part_time_salary;
          this.fullTimeSalary = response.data.data.full_time_salary;
          this.interview = response.data.data.interview;
          this.responseInterviewSlots = response.data.data.interview_slots;
          if (this.status.id == 'scheduled') {
            this.cancel();
          }

          this.options = this.options.filter((option) => option.value === "Unavailable");
          this.options = [
            ...response.data.data.interview_slots.map((slot) => ({
              value: slot.start,
              id: slot.id,
              slot: slot,
            })),
            ...this.options,
          ];

          if (response.data.data.status.id == "scheduled") {
            if (this.$i18n.locale == "en") {
              moment.locale("en");
            } else {
              moment.locale("ar");
            }

            this.selected = this.options.find((option) =>
              moment(option.value).isSame(
                moment(response.data.data.interview.meeting_start_time)
              )
            );
            // this.isSelectedSlotSuccessfullyOpen = true;
          }
          if (response.data.data.status.id == "talent_unavailable") {
            this.selected = this.options.find(
              (option) => option.value == "Unavailable"
            );
            this.isSelectedSlotDeclineOpen = true;
          }
        })
        .catch((error) => {
          toast.error(error.response.data?.message || error.message);
          this.cancel();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$router.push({ path: "/sq/talent/profile" });
    },
    confirm($event) {
      this.isChooseInterviewSlotConfirmModelOpen = false;
      this.isUnavailableConfirmModelOpen = false;

      let payload;

      if (this.selected.value === "Unavailable") {
        // Format the date and time for unavailable selection
        moment.locale("en");
        const formattedSlot = this.days.map((day) => {
          const date = moment(day.date).format("YYYY-MM-DD");
          const time = moment(day.time).format("HH:mm");
          return `${date} ${time}`;
        });

        payload = {
          status: "client_pending",
          slots: formattedSlot,
          timezone: this.selectedTimezone,
        };
      } else {
        payload = {
          status: "scheduled",
          slot_id: this.selected.id,
        };
      }

      this.axios
        .put(`/api/talent-selections/${this.$route.params.id}`, payload)
        .then(() => {
          if ($event == "decline") {
            this.getInterviewSlots(false);
          } else {
            this.isSelectedSlotSuccessfullyOpen = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      if (this.selected == null) {
        this.error = true;
        return;
      }
      this.error = false;
      if (this.selected.value == "Unavailable") {
        this.isUnavailableConfirmModelOpen = true;
      } else {
        this.isChooseInterviewSlotConfirmModelOpen = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check-input:focus {
  border-color: rgb(255 198 41 / 25%);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 198 41 / 25%);
}

.form-check-input:checked {
  background-color: #fff;
  border-color: rgb(255 198 41);
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle fill='rgb(255 198 41)'  r='2.5' /%3e%3c/svg%3e");
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
}

.form-check .form-check-input {
  margin-left: 0;
}
</style>

<style lang="scss">
.talent-choose-interveiw-slot {
  .ltr {
    direction: ltr;
  }

  .vs__selected-options {

    height: 50px;

    @media (max-width: 576px) {
      height: 2.9rem;
    }

  }

  .vs__selected {
    font-size: 14px;
  }

  .vc-container {
    box-shadow: #0000004a 0px 0px 0px 0px !important;
    border: 1px solid #f5f5f5;
  }

  .vc-weeks {
    height: 22rem;
    width: 350px;
    margin: auto;

    @media (max-width: 576px) {
      width: 100%;
      height: auto;
      
    }
  }

  .vc-arrows-container {
    width: 55%;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .vc-arrow {
    color: #ffc629;
    font-size: 1.5rem;
  }

  .vc-title {
    color: #161616;
    font-size: 22px;
    font-weight: 700;
  }

  .vc-highlight {
    background-color: #ffc629 !important;
    color: #fff !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .vc-day-content:hover {
    background-color: #ffc629 !important;

    color: #fff !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .vc-day-content:focus:not(:hover):not(.vc-highlight) {
    background-color: #ffc6291c !important;
    color: #000 !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .time-slot {
    .vc-date {
      display: none;
    }

    .vc-time-icon {
      display: none;
    }

    .vc-container {
      border: none;
      height: 2.125rem;
      display: flex;
      align-items: center;
    }

    .vc-select select {
      background-color: #fff;
      border: none;
      width: auto;
      padding: 0 8px 0 8px;
    }

    .vc-select-arrow {
      display: none;
    }

    .vc-am-pm button.active {
      background-color: #ffc629;
      color: #fff;
    }

    .vc-am-pm button.active:focus {
      border-color: #ffc629;
    }

    .vc-am-pm button.active:hover {
      background: #ffc629;
    }
  }
}
</style>
